import React from "react";
import flagIcon from "../../commonResource/images/icons/16.png";
import { Link } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css"; // Import your custom CSS

const Footer = () => {
  return (
    <>
      <footer className="footer-wrapper">
        <div className="container">
          <div className="upper-text-container">
            <p>
              1. Trade In: Trade‑in values vary. iPhone 11 and iPhone 11 Pro
              promotional pricing is after trade‑in of iPhone 8 Plus and iPhone
              X in good condition. Additional trade‑in values require purchase
              of a new iPhone, subject to availability and limits. Must be at
              least 18. Apple or its trade-in partners reserve the right to
              refuse or limit any Trade In transaction for any reason. In‑store
              trade‑in requires presentation of a valid, government-issued photo
              ID (local law may require saving this information). Sales tax may
              be assessed on full value of new iPhone. Additional terms from
              Apple or Apple’s trade-in partners may apply. Monthly pricing:
              Available to qualified customers and requires 0% APR, 24-month
              installment loan with Citizens One or Apple Card Monthly
              Installments and iPhone activation with AT&T, Sprint, T-Mobile, or
              Verizon. Taxes and shipping not included. Additional Apple Card
              Monthly Installments terms are in the{" "}
              <a
                href="https://www.goldmansachs.com/terms-and-conditions/Apple-Card-Customer-Agreement.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Customer Agreement
              </a>
              . Additional iPhone Payments terms are{" "}
              <a href="https://www.apple.com/legal/sales-support/iphoneinstallments_us/">
                here
              </a>
              .
            </p>
            <p>
              2. Subscription required.
              <br />
              <br />
              Magic Keyboard sold separately.
              <br />
              <br />
              Apple TV+ is $4.99/month after free trial. One subscription per
              Family Sharing group. Offer good for 3 months after eligible
              device activation. Plan automatically renews until cancelled.
              Restrictions and other{" "}
              <a href="https://www.apple.com/promo/">terms </a> apply.
            </p>
          </div>
          <div className="footer-links-wrapper row">
            <FooterSection
              title="Shop and Learn"
              links={[
                { name: "Mac", url: "#" },
                { name: "iPad", url: "#" },
                { name: "iPhone", url: "#" },
                { name: "Watch", url: "#" },
                { name: "TV", url: "#" },
                { name: "Music", url: "#" },
                { name: "AirPods", url: "#" },
                { name: "HomePod", url: "#" },
                { name: "iPod touch", url: "#" },
                { name: "Accessories", url: "#" },
                { name: "Gift Cards", url: "#" },
              ]}
            />
            <FooterSection
              title="Services"
              links={[
                { name: "Apple Music", url: "#" },
                { name: "Apple News+", url: "#" },
                { name: "Apple TV+", url: "#" },
                { name: "Apple Arcade", url: "#" },
                { name: "Apple Card", url: "#" },
                { name: "iCloud", url: "#" },
              ]}
              additionalSections={[
                {
                  title: "Account",
                  links: [
                    { name: "Manage Your Apple ID", url: "#" },
                    { name: "Apple Store Account", url: "#" },
                    { name: "iCloud.com", url: "#" },
                  ],
                },
              ]}
            />
            <FooterSection
              title="Apple Store"
              links={[
                { name: "Find a Store", url: "#" },
                { name: "Genius Bar", url: "#" },
                { name: "Today at Apple", url: "#" },
                { name: "Apple Camp", url: "#" },
                { name: "Field Trip", url: "#" },
                { name: "Apple Store App", url: "#" },
                { name: "Refurbished and Clearance", url: "#" },
                { name: "Financing", url: "#" },
                { name: "Apple Trade In", url: "#" },
                { name: "Order Status", url: "#" },
                { name: "Shopping Help", url: "#" },
              ]}
            />
            <FooterSection
              title="For Business"
              links={[
                { name: "Apple and Business", url: "#" },
                { name: "Shop for Business", url: "#" },
              ]}
              additionalSections={[
                {
                  title: "For Education",
                  links: [
                    { name: "Apple and Education", url: "#" },
                    { name: "Shop for College", url: "#" },
                  ],
                },
                {
                  title: "For Healthcare",
                  links: [
                    { name: "Manage Your Apple ID", url: "#" },
                    { name: "Apple Store Account", url: "#" },
                    { name: "iCloud.com", url: "#" },
                  ],
                },
                {
                  title: "For Government",
                  links: [
                    { name: "Apple and Education", url: "#" },
                    { name: "Shop for College", url: "#" },
                  ],
                },
              ]}
            />
            <FooterSection
              title="Apple Values"
              links={[
                { name: "Find a Store", url: "#" },
                { name: "Genius Bar", url: "#" },
                { name: "Today at Apple", url: "#" },
                { name: "Apple Camp", url: "#" },
                { name: "Field Trip", url: "#" },
                { name: "Apple Store App", url: "#" },
              ]}
              additionalSections={[
                {
                  title: "About Apple",
                  links: [
                    { name: "Find a Store", url: "#" },
                    { name: "Genius Bar", url: "#" },
                    { name: "Today at Apple", url: "#" },
                    { name: "Apple Camp", url: "#" },
                    { name: "Field Trip", url: "#" },
                    { name: "Apple Store App", url: "#" },
                  ],
                },
              ]}
            />
          </div>
          <div className="my-apple-wrapper">
            More ways to shop: <a href="#">Find an Apple Store</a> or{" "}
            <a href="#">other retailer</a> near you. Or call 1-800-MY-APPLE.
          </div>
          <div className="copyright-wrapper row">
            <div className="copyright col-sm-12 order-2 col-md-8 order-md-1 col-lg-4 order-lg-1">
              Copyright &copy; 2020 Apple Inc. All rights reserved.
            </div>
            <div className="footer-links-terms col-sm-12 order-3 col-lg-6 order-lg-2">
              <ul>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms of Use</a>
                </li>
                <li>
                  <a href="#">Sales and Refunds</a>
                </li>
                <li>
                  <a href="#">Legal</a>
                </li>
                <li>
                  <a href="#">Site Map</a>
                </li>
              </ul>
            </div>
            <div className="footer-country col-sm-12 order-1 col-md-4 order-md-2 text-md-right col-lg-2 order-lg-3">
              <div className="flag-wrapper">
                <img src={flagIcon} alt="flag" />
              </div>{" "}
              <div className="footer-country-name">United States</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const FooterSection = ({ title, links, additionalSections = [] }) => {
  return (
    <div className="col-sm-12 col-md">
      <DropdownButton
        id={`dropdown-${title}`}
        title={title}
        drop="up"
        variant="secondary"
        className="w-100 mb-3 d-block d-md-none" // Apply drop-up on mobile only
      >
        {links.map((link, index) => (
          <Dropdown.Item key={index} as={Link} to={link.url}>
            {link.name}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {additionalSections.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <DropdownButton
            id={`dropdown-${section.title}`}
            title={section.title}
            drop="up"
            variant="secondary"
            className="w-100 mb-3 d-block d-md-none" // Apply drop-up on mobile only
          >
            {section.links.map((link, linkIndex) => (
              <Dropdown.Item key={linkIndex} as={Link} to={link.url}>
                {link.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      ))}
      <div className="d-none d-md-block">
        <h3>{title}</h3>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.url}>{link.name}</a>
            </li>
          ))}
        </ul>
        {additionalSections.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <h3>{section.title}</h3>
            <ul>
              {section.links.map((link, linkIndex) => (
                <li key={linkIndex}>
                  <a href={link.url}>{link.name}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Footer;
