import React from 'react'

function Four04() {
  return (
      <div>
          <br />
          <br />
          <br />
          <h1>404 page not found</h1>
    </div>
  )
}

export default Four04