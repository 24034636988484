import { useEffect, useState } from "react";
import "./AppleYouTube.css";
function AppleYouTube() {
  const [youtubeVideos, setYoutubeVideos] = useState([]);
  useEffect(() => {
    fetch(
      "https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCE_M8A5yxnLfW0KghEeajjw&maxResults=10&order=date&key=AIzaSyDDxf_CMpOApHhnmWqlE-pW5dCw3wO07kA"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setYoutubeVideos(data.items);
      });
  }, []);
  return (
    <>
      <section className="apple-youtube">
        <h1>Apple YouTube videos</h1>
        <div className="container">
          <div className="row">
            {youtubeVideos?.map((video) => {
              let videoId = video.id.videoId;
              let videoLink = `https://www.youtube.com/watch?v=${videoId}`;
              let videoWrapper = (
                <div className="video-wrapper col-sm-12 col-md-6">
                  <a href={videoLink} target="_blank" rel="noreferrer">
                    <img src={video.snippet.thumbnails.high.url} alt="" />
                  </a>
                  <br />
                  <a href={videoLink} target="_blank" rel="noreferrer">
                    {video.snippet.title}
                  </a>
                  <br />
                  <a href={videoLink} target="_blank" rel="noreferrer">
                    {video.snippet.description}
                  </a>
                  <br />
                </div>
              );
               return videoWrapper;
            })}
          </div>
        </div>
      </section>
    </>
  );
}
export default AppleYouTube;
