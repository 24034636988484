import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
// import '../../Resources/apple-responsive-replica-html-css-mar-2020/js/bootstrap.js';
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import AppleYouTube from "./components/appleYouTube/AppleYouTube";
// import "./commonResource/js/bootstrap.js";
import "./commonResource/css/bootstrap.css";
import "./commonResource/css/styles.css";
import Main from "./Main";
import Mac from "./components/allLists/mac/Mac";
import Iphone from "./components/allLists/iphone/Iphone";
import Ipad from "./components/allLists/ipad/Ipad";
import Watch from "./components/allLists/watch/Watch";
import Tv from "./components/allLists/tv/Tv";
import Music from "./components/allLists/Music/Music";
import Support from "./components/allLists/Support/Support";
import Cart from "./components/allLists/cart/Cart";
import Search from "./components/allLists/search/Search";
import Shared from "./components/shared/Shared";
import Four04 from "./components/four04/Four04";
import ProductPage from "./components/allLists/productPage/ProductPage";

function App() {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Shared />} >
        <Route path="/" element={<Main />} />
        <Route path="mac" element={<Mac />} />
        <Route path="iphone" element={<Iphone />} />
        <Route path="iphone/:productId" element={<ProductPage />} />
        <Route path="ipad" element={<Ipad />} />
        <Route path="watch" element={<Watch />} />
        <Route path="tv" element={<Tv />} />
        <Route path="music" element={<Music />} />
        <Route path="support" element={<Support />} />
        <Route path="Cart" element={<Cart />} />
        <Route path="Search" element={<Search />} />
        <Route path="*" element={<Four04 />} />
        </Route >
      </Routes>
      {/* <Footer /> */}
    </>
  );
}
export default App;
