import React from 'react'
import Alert from './components/alert/Alert'
import SectionOne from './components/sectionOne/SectionOne'
import SectionTwo from './components/sectionTwo/SectionTwo'
import SectionThree from './components/sectionThree/SectionThree'
import SectionFour from './components/sectionFour/SectionFour'
import SectionFive from './components/sectionFive/SectionFive'
import SectionSix from './components/sectionSix/SectionSix'
import AppleYouTube from './components/appleYouTube/AppleYouTube'

function Main() {
  return (
      <>
          <Alert />
          <SectionOne />
          <SectionTwo />   
          <SectionThree />
          <SectionFour />
          <SectionFive />
          <SectionSix />
          <AppleYouTube />
    </>
  )
}

export default Main