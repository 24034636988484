import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Four04 from '../../four04/Four04';
function ProductPage() {
    const [product, setProduct] = useState({})
    const { productId } = useParams();
    useEffect(() => {
        fetch('http://localhost:3030/iphones')
            .then(res => res.json())
            .then((data) => {
                const productList = data.products;
                const singleProduct = productList.filter((product) => {
                    return product.product_url === productId
                });
                setProduct(singleProduct);
            })
            .catch(() => {
                console.log('Error: unable to fetch data')
            })
    }, [productId]);
    if (product.length) {
   
  return (
      <div>
          <section className="internal-page-wrapper">
              <div className="container">
                  {product.map((product) => {
                      return (
                        <div className="row text-center justify-content-center">
                          <div className="col-12 mt-5 pt-5">
                            <h1 className="font-weight-bold">
                              {product.product_name}
                            </h1>
                            <div className="product-brief">
                              {product.product_brief_description}
                            </div>
                            <div className="starting-price">
                              {`Starting at ${product.starting_price}`}
                            </div>
                            <div className="monthly-price">
                              {product.price_range}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="product-image">
                              <img src={product.product_img} alt="product" />
                            </div>
                          </div>
                        </div>
                      );
                  })
                  }
              </div>
          </section>
    </div>
        )
    } else {
        return <Four04 />
    }
}

export default ProductPage