import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class HeaderLinks extends Component {
    render() {
        const  { linkUrl, linkName } = this.props;
        return (
          <>
            <li className="nav-item">
              <Link className="nav-link js-scroll-trigger" to={linkUrl}>
                {linkName}
              </Link>
            </li>
          </>
        );
    }
}

export default HeaderLinks;
