import React from 'react'

function Ipad() {
  return (
    <div>
      <br />
      <br />
      <br />
      Ipad
      <p>lorem lorem eihuiqh3 r3jruo2hu2hr32n u2hru23hroi2jr jrhu2orh2ior
        ihrio2jrio2jroi2jri23jrip23 iirji2jri2jrip2jr32i 2nrio23jri32
      </p>
    </div>
  )
}

export default Ipad