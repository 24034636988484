import React, { Component } from "react";
import logo from "../../commonResource/images/icons/logo.png";
import search from "../../commonResource/images/icons/search-icon-sm.png";
import cart from "../../commonResource/images/icons/cart-sm.png";
import HeaderLinks from "./HeaderLinks";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

class Header extends Component {
  render() {
    const links = [
      { linkName: "Mac", linkUrl: "/Mac" },
      { linkName: "iPhone", linkUrl: "/Iphone" },
      { linkName: "iPad", linkUrl: "/Ipad" },
      { linkName: "Watch", linkUrl: "#" },
      { linkName: "TV", linkUrl: "#" },
      { linkName: "Music", linkUrl: "#" },
      { linkName: "Support", linkUrl: "#" },
    ];

    return (
      <div>
        <div className="nav-wrapper fixed-top">
          <div className="container">
            <nav className="navbar navbar-toggleable-sm navbar-expand-md">
              <button
                className="navbar-toggler navbar-toggler-right"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse"
              >
                ☰
              </button>
              <Link className="navbar-brand mx-auto" to="/">
                <img src={logo} alt="Logo" />
              </Link>

              <div className="navbar-collapse collapse">
                <ul className="navbar-nav nav-justified w-100 nav-fill">
                  {links.map((link, index) => (
                    <HeaderLinks
                      key={index}
                      linkUrl={link.linkUrl}
                      linkName={link.linkName}
                    />
                  ))}
                  <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger" to="/search/">
                      <img src={search} alt="Search" />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link js-scroll-trigger" to="/cart/">
                      <img src={cart} alt="Cart" />
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
